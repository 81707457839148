import React from 'react';
import * as styles from './Spinner.module.scss'

// css spinner using border-top and css animation
// provide size="100" to set the height and width to 100px
// defaults to 55px if size not provided

const Spinner = (props) => {

  const width = props.size || '55'
  const height = props.size || '55'

  const style = {
    width: `${width}px`,
    height: `${height}px`
  }

  return <span className={styles.circle} style={style}></span>;
};

export default Spinner;
