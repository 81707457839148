import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as styles from './ContactHero.module.scss'

export default function ContactHero ({ data }) {
    return (
        <div className={styles.contactHero}>
            {data.contact.backgroundImage &&
                <div className={styles.heroImage}>
                    <GatsbyImage
                        alt={`title ${data.heroTitle}`}
                        image={data.contact.backgroundImage.asset.gatsbyImageData}
                        className={styles.img}>
                    </GatsbyImage>
                </div>
            }
                <div className={styles.topContent}>
                    <h1>{data.contact.heroTitle}</h1>
                    <p>{data.contact.heroContent}</p>
                </div>
        </div>
    )
}
