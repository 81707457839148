import React from 'react';
import * as styles from './FilterBreak.module.scss'

/* 
    props:
    height: provide as number to set padding top
*/

export default function FilterBreak(props) {

    const { height } = props

    // padding = total height 124 - h1 height 32 for most pages
    const style = { paddingTop: height || 92 + "px" }

    return (
        <div
            id="filter-break"
            className={styles.FilterBreak}
            style={style}>
        </div>
    )
}
