import React, { useEffect, useState, useRef } from 'react'
import Spinner from '../Spinner/Spinner'
import * as styles from './MarketoContactForm.module.scss'
import { nanoid } from 'nanoid'

const MarketoContactForm = ({ maxWidth, formId, formCode, heading, subheading, variant }) => {
  const [isLoading, setIsLoading] = useState(true)
  const instanceId = nanoid()
  // ref for form element
  const ref = useRef(null)
  // track if component is mounted to prevent memory leaks for async functions
  // const isMounted = useRef(true)

  // handle multiple forms on the same page by appending a unique id to the form id while the form is loading
  // and then removing the id after the form is ready
  useEffect(() => {
    const baseUrl = 'https://offers.premierinc.com'
    const munchkinId = '381-NBB-525'
    const formEl = ref.current

    const loadForm = async () => {
      if (formEl) {
        formEl.id = `mktoForm_${formCode}`
      }

      try {
        await window.MktoForms2.loadForm(baseUrl, munchkinId, formCode)
      } catch (error) {
        console.error('Error loading Marketo form:', error)
      } finally {
      }
    }

    loadForm()
  }, [formCode, ref])

  //  clear form id after form is ready to prevent conflicts with other forms
  useEffect(() => {
    const isMounted = { current: true }

    window.MktoForms2?.onFormRender(() => {
      if (ref.current && isMounted.current) {
        ref.current.id = ''
        setIsLoading(false)
      }
    })

    return () => {
      isMounted.current = false
    }
  }, [ref])

  // set placeholder text on form fields for form 4250 only
  useEffect(() => {
    const setPlaceholderText = () => {
      if (document.getElementById(formId)) {
        document.getElementById('FirstName')?.setAttribute('placeholder', '')
        document.getElementById('LastName')?.setAttribute('placeholder', '')
        document.getElementById('Email')?.setAttribute('placeholder', '')
        document.getElementById('Company')?.setAttribute('placeholder', '')
        document.getElementById('Title')?.setAttribute('placeholder', '')
        document.getElementById('Phone')?.setAttribute('placeholder', '')
        document.getElementById('Tell_Us_More__c')?.setAttribute('placeholder', '')
      }
    }

    // use marketo callback to set the placeholder text on contact page form
    if (formId === 'mktoForm_4250') {
      window.MktoForms2?.whenReady((form) => {
        setPlaceholderText()
      })
    }
  }, [formId, formCode])

  const maxWidthString = `${maxWidth || '1200'}px`

  const customStyle = {
    maxWidth: maxWidthString,
  }

  // text from props or defaults to empty string

  const headingText = heading || 'Let’s Talk.'
  const subheadingText =
    subheading || 'Fill out the form below to connect with our team. We look forward to hearing from you.'

  return (
    <>
      {/* {!isScriptLoaded && (
        <Script
          id={marketoScriptId}
          src="//offers.premierinc.com/js/forms2/js/forms2.min.js"
          onLoad={() => setIsLoaded(true)}
        />
      )} */}
      <section
        id="contact-form"
        className={
          styles.container +
          ' ' +
          (variant && (variant === 'appSci' || variant === 'stanson') ? styles.appSciForm : null)
        }
        style={customStyle}
      >
        <h3>{headingText}</h3>
        <p className={styles.subheading}>{subheadingText}</p>
        <div className={styles.formWrapper}>
          {isLoading && (
            <div className={styles.spinnerContainer}>
              <Spinner />
            </div>
          )}
          <form data-form-id={formId} data-form-instance={instanceId} ref={ref}></form>
        </div>
      </section>
    </>
  )
}

export default MarketoContactForm
