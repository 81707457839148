import React from 'react'
import { graphql } from 'gatsby'
import ContactHero from '../components/ContactHero/ContactHero'
import ContactMain from '../components/ContactMain/ContactMain'
import FilterBreak from '../components/FilterBreak/FilterBreak'
import Layout from '../components/Layout/Layout'
// import { Script } from "gatsby"

import * as styles from '../styles/contact.module.scss'
import Seo from '../components/Seo/Seo'

export const query = graphql`
  query contact {
    contact: sanityContactPage {
      browserTitle
      seoData {
        title
        description
        image {
          asset {
            url
          }
        }
      }
      heroTitle
      heroContent
      backgroundImage {
        asset {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 1600)
        }
      }
    }
  }
`

const ContactTemplate = (props) => {
  const { data } = props
  return (
    <Layout>
      <div className={styles.contactWrap}>
        {/* <h1 id="news-header" className={styles.newsHeader}>
          <span><img src={pincailogo} alt="Pinc AI Logo" /></span>
          <span>Contact</span>
        </h1> */}

        <FilterBreak height={120} />

        <ContactHero data={data} />

        <ContactMain />
      </div>
    </Layout>
  )
}

export default ContactTemplate

export const Head = (props) => {
  const { data } = props
  return <Seo title={data.contact.browserTitle} seo={data.contact.seoData} />
}
