import React from 'react';
import MarketoContactForm from '../ContactForm/MarketoContactForm';
import * as styles from './ContactMain.module.scss'

export default function ContactMain ({ data }) {
    return (
        <div className={styles.contactMain}>

            <div className={styles.leftContent}>
                <div className={styles.sideBarWrapper} >
                    {/* <script src="https://offers.premierinc.com/js/forms2/js/forms2.min.js"></script> */}
                    {/* <MarketoContactForm formID="mktoForm_4250" formCode={4250} /> */}
                    <MarketoContactForm 
                        formID="mktoForm_4250"
                        formCode={4250} 
                        heading='Let’s Talk.'
                        subheading='Fill out the form below to connect with our team. We look forward to hearing from you.'
                    />
                </div>
            </div>

            <div className={styles.rightContent}>
                <div className={styles.sideBarWrapper} >
                    <span className={styles.sideBarBlock}>PINC AI SUPPORT</span>

                    <div className={styles.sideBarItem}>
                        <h2>Solution Center</h2>
                        <p>Contact the Premier Solution Center for all Premier-related questions. </p>
                        <h3>Monday – Friday, 8 a.m. – 8 p.m. EST</h3>
                        <a href="tel:877.777.1552" className={styles.phone}>877.777.1552</a>
                        <a href="mailto:solutioncenter@premierinc.com" className={styles.email}>solutioncenter@premierinc.com</a>
                    </div>

                    <div className={styles.sideBarItem}>
                        <h2>Speakers Bureau</h2>
                        <a href="tel:202.879.4105" className={styles.phone}>202.879.4105</a>
                        <a href="mailto:Public_Relations@premierinc.com" className={styles.email}>Public_Relations@premierinc.com</a>
                    </div>

                    <div className={styles.sideBarItem}>
                        <h2>Investor Relations</h2>
                        <br/>
                        <h3>Angie McCabe</h3>
                        <p>Vice President, Investor Relations</p>
                        <a href="tel:704.816.3888" className={styles.phone}>704.816.3888</a>
                        <a href="mailto:angie_mccabe@premierinc.com" className={styles.email}>angie_mccabe@premierinc.com</a>
                        <br/>
                        <h3>Ben Krasinski</h3>
                        <p>Director, Investor Relations</p>
                        <a href="704.816.5644" className={styles.phone}>704.816.5644</a>
                        <a href="mailto:angie_mccabe@premierinc.com" className={styles.email}>angie_mccabe@premierinc.com</a>
                    </div>


                    <div className={styles.sideBarItem}>
                        <h2>Public Relations</h2>
                        <a href="tel:212.901.1356" className={styles.phone}>212.901.1356</a>
                        <a href="mailto:Public_Relations@premierinc.com" className={styles.email}>Public_Relations@premierinc.com</a>
                    </div>

                </div>
            </div>

        </div>
    )
}
